import React from 'react'
import { Link } from 'gatsby'

import AnimatedArrow from './animated-arrow'
import Pattern from '../images/tuya-pattern-menu.inline.svg'

import '../styles/components/featured-card.scss'

const FeaturedCard = ({ description, title, link, className }) => (
  <div className={`featured-card-wrapper ${className}`}>
    <Pattern className="text-tuya-slate-light" />
    <p dangerouslySetInnerHTML={{ __html: description }} />
    <Link className="text-3xl text-tuya-slate-light" to={link}>
      {title}
      <AnimatedArrow className="h-3 text-tuya-dark" />
    </Link>
  </div>
)

export default FeaturedCard
