import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import FeaturedCard from '../components/FeaturedCard'

// Styles
import '../styles/pages/private-dining.scss'

const PrivateDiningPage = ({ data: graphqlData, location }) => {
  const {
    pageTitle,
    pageDescription,
    pageImage,
    sectionTitle,
    sectionDescription,
    featuredTitle,
    featuredDescription,
  } = graphqlData.datoCmsPrivateDining


  return (
    <Layout className="private-dining-page" pathname={location.pathname} alwaysBlack>
      <SEO title="Private Dining" />

      <section className="container">
        <div className="main-section section">
          <h1>{pageTitle}</h1>
          <div dangerouslySetInnerHTML={{ __html: pageDescription }} />
        </div>

        <figure className="page-image">
          <img src={pageImage.sizes.src} srcSet={pageImage.sizes.srcSet} alt="Private Dining" />
        </figure>

        <div className="second-section section">
          <h3>{sectionTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: sectionDescription }} />
        </div>

        <FeaturedCard
          className="featured"
          title={featuredTitle}
          description={featuredDescription}
          link="/reservation"
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsPrivateDining {
      pageTitle
      pageDescription
      pageImage {
        sizes {
          src
          srcSet
        }
      }
      sectionTitle
      sectionDescription
      featuredTitle
      featuredDescription
    }
  }
`

export default PrivateDiningPage
